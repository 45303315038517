<template>
    <div id="dance_wear">
        <inc_header></inc_header>

        <div class="container">
            <div class="banner"><el-image :src="banner.srcPc" fit="cover"></el-image></div>

            <div class="show">
                <section v-for="c in categories" :key="c.categoryId">
                    <div class="area">
                        <h2>{{ c.sign }}</h2>
                        <dl v-for="d in c.danceWear" :key="d.wearId">
                            <dt><a :href="d.href" target="_blank"><el-image :src="d.thumbnail" fit="cover"></el-image></a></dt>
                            <dd>
                                <span>MichelleDance</span>
                                <br>
                                <a :href="d.href" target="_blank">{{ d.sign }}</a>
                            </dd>
                        </dl>
                    </div>
                </section>
            </div>
        </div>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    .banner { height:480px; }
    .banner .el-image { width:100%; height:100%; }

    .show { margin:60px 0; }
    .show section { height:auto; padding:60px 0 20px 0; border-top:1px solid #ddd; }
    .show section:first-child { padding-top:0; border-top:none; }
    .show section:last-child { padding-bottom:0; }
    .show section h2 { margin:0 0 35px 0; font-size:24px; font-weight:400; color:#c00; }
    .show section .area { width:1240px; height:auto; overflow:hidden; }
    .show section .area:after { height:0; display:block; content:''; clear:both; visibility:hidden; }
    .show section .area dl { width:270px; height:auto; margin:0 40px 30px 0; float:left; }
    .show section .area dl dt { height:360px; }
    .show section .area dl dt .el-image { width:270px; height:360px; }
    .show section .area dl dd { padding:10px 0 0 0; margin:0; }
    .show section .area dl dd span { color:#c00; }
    .show section .area dl dd a { width:100%; color:#999; text-decoration:none; display:block; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; }
    .show section .area dl dd a:hover { color:#000; }
</style>

<script>
    import inc_header from "../components/inc_header";
    import inc_footer from "../components/inc_footer";

    export default {
        name: "dance_wear",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                banner: {},  // Banner
                categories: []  // “舞蹈服装”类别
            }
        },
        created() {
            let that = this;

            // 获取“Banner”
            this.$axios.post(this.$global.config.serverApi+'/banner.php',{ action:"getByCategory", category:"danceWear" }).then( function(response) {
                if (response.data.complete === true) {
                    that.banner = response.data.banner;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“舞蹈服装”类别及产品
            this.$axios.post(this.$global.config.serverApi+'/dance_wear_category.php',{ action:"list", status:"open", withDanceWear:1, page:0, rowsPerPage:0 }).then( function(response) {
                if (response.data.complete === true) {
                    that.categories = response.data.categories;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }
    }
</script>
